




import { Vue, Component, Prop } from 'vue-property-decorator';
import { ProductSolutionOpportunityCollection } from '~/types/Product';
import SolutionOpportunity from '~/patterns/organisms/_content-blocks/solution-opportunity/solution-opportunity.vue';

const Carousel = () => import(/* webpackPrefetch: true */ '~/patterns/organisms/carousel/carousel.vue');

@Component({
    components: { SolutionOpportunity, Carousel }
})
export default class SolutionOpportunities extends Vue {
    @Prop() readonly solutionName?: string;

    @Prop() readonly opportunities!: ProductSolutionOpportunityCollection;

    get items(): number {
        // XXX: Prevent casting errors by converting to any first?
        // eslint-disable-next-line
        return this.opportunities ? ((this.opportunities as any) as object[]).length : 0;
    }

    get carouselSettings(): object {
        return {
            slidesPerView: 1.25,
            center: false,
            spaceBetween: 24,
            breakpointsInverse: true,
            breakpoints: {
                // hard-coded breakpoint..
                480: {
                    slidesPerView: 2
                },
                // hard-coded breakpoint..
                992: {
                    slidesPerView: this.items > 2 ? 3 : this.items
                }
            }
        };
    }
}
