











































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';

import { MunicipalityType } from '~/types/Municipality';
import { PaginationProperties } from '~/interfaces/pagination';
import { FinancialBenefitEntry } from '~/types/FinancialBenefit';
import { FilterDefinition } from '~/types/FilterDefinition';
import randomInt from '~/helpers/randomInt';

import BenefitCard from '~/patterns/molecules/_cards/financial-benefit/financial-benefit.vue';
import Pagination from '~/patterns/molecules/pagination/pagination.vue';
import ActiveFilters from '~/patterns/molecules/active-filters.vue';
import Spinner from '~/patterns/atoms/spinner/spinner.vue';
import ModalOpener from '~/patterns/molecules/modal-opener/modal-opener.vue';
import GhButton from '~/patterns/atoms/button/button.vue';

@Component({
    components: { BenefitCard, Pagination, ActiveFilters, Spinner, ModalOpener, GhButton }
})
export default class FinancialBenefitOverview extends Vue {
    @Prop() readonly activeFilters: FilterDefinition[];
    perPage: number = 4;
    currentPage: number = 0;
    id: string;
    loading: boolean = false;

    // Which store actions to watch in order to update loading property
    actionsToWatch = ['financialbenefits/loadpage', 'financialbenefits/SETTINGS', 'financialbenefits/UPDATE'];

    constructor() {
        super();
        this.perPage = 4;
        this.currentPage = 1;
        this.id = 'id-' + randomInt(1, 9999999);
    }

    get totalPages(): number {
        return this.$store.getters['financialbenefits/totalPages'];
    }

    get totalEntries(): number {
        return this.$store.getters['financialbenefits/totalEntries'];
    }

    get municipality(): MunicipalityType {
        return this.$store.getters['municipality/municipality'];
    }

    get benefits(): FinancialBenefitEntry[] | void {
        return this.$store.getters['financialbenefits/financialBenefits'];
    }

    get paginationProperties(): PaginationProperties {
        return {
            pageNumber: this.currentPage,
            perPage: this.perPage
        };
    }

    loadBenefitsFromStore(): Promise<void | FinancialBenefitEntry[]> {
        return this.$store.dispatch('financialbenefits/loadpage', {
            filters: this.activeFilters,
            pagination: this.paginationProperties,
            municipality: this.municipality
        });
    }

    loadBenefitsAndParams() {
        if (this.$route.query && this.$route.query.page) {
            this.currentPage = Number(this.$route.query.page);
        }
        this.loadBenefitsFromStore();
    }

    serverPrefetch(): Promise<void | FinancialBenefitEntry[]> | undefined {
        if (!this.benefits) {
            return this.loadBenefitsFromStore();
        }
        return undefined;
    }

    created() {
        // Set loading to true before action is handled, set to false after action completes.
        this.$store.subscribeAction({
            before: action => {
                if (this.actionsToWatch.some(type => type === action.type)) {
                    this.loading = true;
                }
            },
            after: action => {
                if (this.actionsToWatch.some(type => type === action.type)) {
                    this.loading = false;
                }
            }
        });
    }

    @Watch('municipality', { immediate: true })
    @Watch('paginationProperties', {})
    onPageChange() {
        this.loadBenefitsFromStore();
    }
}
