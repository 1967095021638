


import { Component, Vue, Prop } from 'vue-property-decorator';
import { Document } from '@contentful/rich-text-types';
import { format, parseISO } from 'date-fns';
import { HasImage } from '~/interfaces/image';
import { getImage } from '~/helpers/image';
import RichTextRenderer from '~/patterns/molecules/rich-text/rich-text.vue';
import Hero from '~/patterns/organisms/hero/hero.vue';
import ZipcodeEntry from '~/patterns/molecules/zipcode-entry/zipcode-entry.vue';
import GhButton from '~/patterns/atoms/button/button.vue';
import GhLink from '~/patterns/atoms/link/link.vue';
import CloudinaryImage from '~/patterns/atoms/cloudinary-image/cloudinary-image.vue';
import { MunicipalityBannerEntry } from '~/types/MunicipalityBanner';

// The @Component decorator indicates the class is a Vue component
@Component({
    components: { Hero, RichTextRenderer, ZipcodeEntry, GhButton, GhLink, CloudinaryImage }
})
export default class GhHero extends Vue {
    @Prop() readonly hero!: MunicipalityBannerEntry;

    @Prop(String) readonly pageType!: string;

    @Prop() readonly cdnImage!: object;

    get hasMunicipality(): boolean {
        return this.pageType === 'page-municipality';
    }

    get hasZipcodeEntry(): boolean {
        return !!(this.hero && this.hero.fields && this.hero.fields.showZipcodeEntry);
    }

    get heroContent(): object {
        if (this.hero && this.hero.fields !== undefined) {
            const fields = this.hero.fields;
            return {
                title: fields.title,
                subtitle: fields.subtitle || undefined,
                content: fields.content || undefined,
                labelPostal: fields.labelPostal || undefined,
                textPrivacyPolicy: fields.textPrivacyPolicy || undefined,
                buttonLabel: fields.buttonLabel,
                href: fields.href
            };
        }
        return {};
    }

    get heroClassNames(): string {
        if (this.hasMunicipality) {
            return 'hero--municipality';
        } else if (this.hasZipcodeEntry) {
            return 'hero--zipcode-entry';
        }
        return '';
    }

    get showPublishingDate(): boolean {
        return this.pageType === 'article';
    }

    get publishingDate(): string {
        return this.showPublishingDate && this.hero && this.hero.sys.createdAt
            ? format(parseISO(this.hero.sys.createdAt), 'd-M-yyyy')
            : '';
    }

    get singleLineTitle(): boolean {
        if (!this.hero.fields) {
            return false;
        }
        if (typeof this.hero.fields.title === 'string') {
            return true;
        }
        const { content } = this.hero.fields.title as Document;
        return content[0].content.length === 1;
    }

    get image(): HasImage | undefined {
        if (this.hero) {
            return getImage(this.hero.fields);
        }
        return undefined;
    }
}
