








































































import { Vue, Component, Prop } from 'vue-property-decorator';
import { MediaOnlyFields } from '~/types/MediaOnly';
import { HasImage } from '~/interfaces/image';
import { getImage } from '~/helpers/image';

import CloudinaryImage from '~/patterns/atoms/cloudinary-image/cloudinary-image.vue';
import RichTextRenderer from '~/patterns/molecules/rich-text/rich-text.vue';
import Accordion from '~/patterns/organisms/accordion/accordion.vue';
import AccordionItem from '~/patterns/molecules/accordion-item/accordion-item.vue';

@Component({
    components: { CloudinaryImage, Accordion, AccordionItem, RichTextRenderer }
})
export default class MediaOnly extends Vue {
    @Prop() readonly fields!: MediaOnlyFields;
    videoIsPlaying = false;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    $gtm: any;

    get modifiers(): string {
        let modifiers = '';
        if (this.fields) {
            if (this.fields.maxWidth === '50%') {
                modifiers += 'media-only--50';
            } else if (this.fields.maxWidth === '75%') {
                modifiers += 'media-only--75';
            }
        }
        if (this.poster) {
            modifiers += ' media-only__video-player--has-poster';
        }
        if (this.fields.controls) {
            modifiers += ' media-only__video-player--has-controls';
        }
        return modifiers;
    }

    get image(): HasImage {
        return getImage(this.fields as MediaOnlyFields, 'media');
    }

    get ytVideo(): string {
        return `https://www.youtube.com/embed/${this.fields.youtubeVideoID}`;
    }

    get coverImage(): HasImage {
        return getImage(this.fields as MediaOnlyFields, 'media', 'coverImage');
    }

    get poster(): string | boolean | undefined {
        if (!this.fields.autoplay && this.coverImage.cloudinaryImage) {
            return this.coverImage.cloudinaryImage.secure_url;
        }
        return false;
    }

    get sourceSizes(): number[] {
        if (this.fields) {
            if (this.fields.maxWidth === '50%') {
                return [450, 250, 560, 340];
            } else if (this.fields.maxWidth === '75%') {
                return [720, 560, 560, 340];
            }
        }
        return [1024, 768, 576, 340];
    }

    playVideo() {
        this.videoIsPlaying = true;
        (this.$refs.videoPlayer as HTMLMediaElement).play();
    }

    pauseVideo() {
        this.videoIsPlaying = false;
        (this.$refs.videoPlayer as HTMLMediaElement).pause();
    }

    trackVideo(action: string) {
        const label = this.fields.youtubeVideoID || this.image?.cloudinaryImage?.secure_url || '';
        // eslint-disable-next-line no-console
        console.log({ category: 'Videos', action, label });
        !!this.$gtm && this.$gtm.pushEvent({ category: 'Videos', action, label });
    }

    get showVideoPlay(): boolean {
        if (!this.fields.controls && this.poster && !this.videoIsPlaying) {
            return true;
        }
        return false;
    }

    mounted() {
        const player = this.$refs.youtubePlayer as HTMLElement | null;
        if (player) {
            setInterval(() => {
                if (document.activeElement === player) {
                    this.videoIsPlaying = !this.videoIsPlaying;
                    this.trackVideo(this.videoIsPlaying ? 'Play' : 'Pause');
                    player.blur();
                }
            }, 100);
        }
    }
}
