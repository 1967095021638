



























































































import { Vue, Component, Prop } from 'vue-property-decorator';
import { FinancialBenefitEntry } from '~/types/FinancialBenefit';
import RichTextRenderer from '~/patterns/molecules/rich-text/rich-text.vue';
import CloudinaryImage from '~/patterns/atoms/cloudinary-image/cloudinary-image.vue';
import Accordion from '~/patterns/organisms/accordion/accordion.vue';
import AccordionItem from '~/patterns/molecules/accordion-item/accordion-item.vue';

@Component({
    components: { RichTextRenderer, CloudinaryImage, Accordion, AccordionItem }
})
export default class FinancialBenefit extends Vue {
    @Prop(String) readonly id!: string;

    @Prop() item!: FinancialBenefitEntry;

    @Prop() readonly type!: string;

    accordionOpen = false;
}
