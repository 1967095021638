











































































































import { Vue, Component } from 'vue-property-decorator';
import Cookiebar from './dn-cookiebar/cookiebar.vue';

@Component({
    components: { Cookiebar }
})
export default class GhCookieBar extends Vue {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    $gtm: any;

    acceptCookies() {
        !!this.$gtm && this.$gtm.pushEvent({ event: 'cookiesAccepted' });
    }

    rejectCookies() {
        !!this.$gtm && this.$gtm.pushEvent({ event: 'cookiesRejected' });
    }
}
