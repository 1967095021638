




import { Vue, Component, Prop } from 'vue-property-decorator';
import { PopulairInFields } from '~/types/PopularIn';
import { MunicipalityFields } from '~/types/Municipality';
import PopularInCard from '~/patterns/molecules/_cards/popular-in/popular-in.vue';
import slugify from '~/helpers/slugify';

export const NUMBER_OF_ITEMS_DISPLAYED = 6;

@Component({
    components: { PopularInCard }
})
export default class PopularIn extends Vue {
    @Prop() readonly fields!: PopulairInFields;

    @Prop() readonly municipality!: MunicipalityFields;

    get items() {
        // filter out the items that have a zipcode defined but it doesn't match the user specified one
        // Double check to see if the reference still exists (i.e. not a reference to a deleted entry).
        const items = this.fields.items
            .filter(item => item.fields)
            .filter(item => !('zipcodes' in item.fields) || item.fields.zipcodes.some(zip => zip === this.zipcode))
            .filter(item => item.fields.reference && item.fields.reference.fields);

        return items.slice(0, NUMBER_OF_ITEMS_DISPLAYED);
    }

    get zipcode(): string | undefined {
        const location = this.$store.getters['location/location'];

        if (location && location.postcode) {
            return location.postcode;
        }
        return undefined;
    }

    get municipalityName(): string {
        if (this.municipality && this.municipality.name) {
            return this.municipality.name;
        }
        return '';
    }

    get slug(): string {
        return slugify(this.fields.name) || '';
    }
}
