export const initAnalytics = (gaKey, gaAggKey) => {
    (function(i, s, o, g, r, a, m) {
        i['GoogleAnalyticsObject'] = r;
        (i[r] =
            i[r] ||
            function() {
                (i[r].q = i[r].q || []).push(arguments);
            }),
            (i[r].l = 1 * new Date());
        (a = s.createElement(o)), (m = s.getElementsByTagName(o)[0]);
        a.async = 1;
        a.src = g;
        a.setAttribute('data-cookieconsent', 'statistics');
        m.parentNode.insertBefore(a, m);
    })(window, document, 'script', '//www.google-analytics.com/analytics.js', 'ga');

    if (typeof window.ga === 'function') {
        window.ga('config', gaKey, { anonymize_ip: true });
        window.ga('create', gaKey, 'auto');
        window.ga('config', gaAggKey, { anonymize_ip: true });
        window.ga('create', gaAggKey, 'auto', 'aggregatedTracker');
    }
};
