








import { mixins } from 'vue-class-component';
import { Component, Prop } from 'vue-property-decorator';
import { ContentBlockFields } from '~/types/ContentBlocks';
import RichText from '~/patterns/molecules/rich-text/rich-text.vue';
import DisplayLogicMixin from '~/mixins/DisplayLogic.vue';

@Component({
    name: 'text-only',
    components: { RichText }
})
export default class TextOnly extends mixins(DisplayLogicMixin) {
    @Prop() readonly fields!: ContentBlockFields;
}
