












import { Vue, Component, Prop } from 'vue-property-decorator';
import Modal from '~/patterns/organisms/modal/modal.vue';
import iFrame from '~/patterns/atoms/iframe/iframe.vue';

@Component({
    components: { Modal, ghIframe: iFrame }
})
export default class HuisscanModal extends Vue {
    @Prop() readonly offerURL: string
}
