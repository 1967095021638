import createPersistedState from 'vuex-persistedstate';

export default ({ store }): void => {
    // @ts-ignore
    window.onNuxtReady((): void => {
        createPersistedState({
            key: 'rel-vuex',
            paths: ['location', 'municipality']
        })(store);
        createPersistedState({
            key: 'rel-vuex-session',
            paths: ['alert', 'site'],
            storage: window.sessionStorage
        })(store);
    });
};
