








import { Vue, Component } from 'vue-property-decorator';
import config from '~/config/config';
import Modal from '~/patterns/organisms/modal/modal.vue';
import iFrame from '~/patterns/atoms/iframe/iframe.vue';
import { parseUrl } from '~/helpers/routes';

@Component({
    components: { Modal, ghIframe: iFrame },
    methods: { parseUrl }
})

export default class DynamicIframeModal extends Vue {
    get src(): string | undefined {
        return this.$store.getters['modals/currentLinkSrc'];
    }

    get sandbox(): string {
        // Determine if the src attribute's domain is trusted in our config, if so, allow more code execution.
        if (this.src) {
            const domain = parseUrl(this.src);
            if (domain && config.trustedIframeDomains.find((item): boolean => item === domain)) {
                return 'allow-forms allow-scripts allow-same-origin allow-popups';
            }
            return '';
        }
        return '';
    }
}
