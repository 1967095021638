







import { Vue, Component, Prop } from 'vue-property-decorator';
import { Inline, Text } from '@contentful/rich-text-types';

import GhLink from '~/patterns/atoms/link/link.vue';
import ContentfulButton from '~/patterns/molecules/contentful-button.vue';

import { getPathForEntry } from '~/helpers/routes';

@Component({
    components: { ContentfulKnop: ContentfulButton, GhLink }
})
export default class InlineEntry extends Vue {
    @Prop() node: Inline;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    isText(arg: any): arg is Text {
        return arg !== undefined;
    }

    get targetElementTypeId(): string | undefined {
        if (
            this.node &&
            this.node.data.target &&
            this.node.data.target.sys &&
            this.node.data.target.sys.contentType &&
            this.node.data.target.sys.contentType.sys.id
        ) {
            return this.node.data.target.sys.contentType.sys.id;
        }
        return undefined;
    }

    get linkText(): string | null {
        if (this.node && this.node.content && this.isText(this.node.content[0])) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            return (this.node.content[0] as any as Text).value;
        } else if (this.targetElementTypeId === 'button' && this.targetEntry && this.targetEntry.fields) {
            return this.targetEntry.fields.txt;
        }
        return null;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    get targetEntry(): undefined | any  {
        if (this.node && this.node.data.target) {
            return this.node.data.target;
        } else if (this.node && this.node.data) {
            return this.node.data;
        }
        return undefined;
    }

    get targetUrl(): string | undefined {
        if (this.targetEntry && this.targetEntry.fields && this.targetEntry.fields.destination) {
            return this.targetEntry.fields.destination.fields ? this.targetEntry.fields.destination.fields.url : undefined;
        }
        if (this.targetEntry && this.targetEntry.name) {
            return this.targetEntry.url ? this.targetEntry.url : undefined;
        }
        if (this.targetEntry && this.targetEntry.uri) {
            return this.targetEntry.uri ? this.targetEntry.uri : undefined;
        }
        return getPathForEntry(this.targetEntry);
    }
}
