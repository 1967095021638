




import { Vue, Component, Prop } from 'vue-property-decorator';
import { HasImage } from '~/interfaces/image';
import { getImage } from '~/helpers/image';
import { PopularInCardFields } from '~/types/PopularIn';
import GhLink from '~/patterns/atoms/link/link.vue';
import CloudinaryImage from '~/patterns/atoms/cloudinary-image/cloudinary-image.vue';
import { getPathForEntry } from '~/helpers/routes';

@Component({
    components: { GhLink, CloudinaryImage },
    methods: { getPathForEntry }
})
export default class PopularInCard extends Vue {
    @Prop() item: PopularInCardFields | undefined;

    get itemImage(): HasImage {
        return getImage(this.item as PopularInCardFields);
    }

    get hasItem() {
        // Double check to see if the reference still exists (i.e. not a reference to a deleted entry).
        return this.item && this.item.reference && this.item.reference.sys && this.item.reference.fields;
    }
}
