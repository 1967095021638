




import { Vue, Component, Prop } from 'vue-property-decorator';
import { ProductTreeFields } from '~/types/Product';
import { getPathForEntry } from '~/helpers/routes';
import slugify from '~/helpers/slugify';

import Card from '~/patterns/molecules/_cards/card/card.vue';
import CloudinaryImage from '~/patterns/atoms/cloudinary-image/cloudinary-image.vue';
import GhLink from '~/patterns/atoms/link/link.vue';
import RichTextRenderer from '~/patterns/molecules/rich-text/rich-text.vue';
import Tab from '~/patterns/molecules/tab/tab.vue';
import TabLink from '~/patterns/atoms/tab-link/tab-link.vue';
import Tabs from '~/patterns/organisms/tabs/tabs.vue';

@Component({
    components: { CloudinaryImage, RichTextRenderer, Tabs, Tab, TabLink, GhLink, Card },
    methods: { slugify, getPathForEntry }
})

export default class ProductTree extends Vue {
    @Prop() readonly fields!: ProductTreeFields;

    activeId: number;

    constructor() {
        super();
        this.activeId = 0;
    }

}
