




import axios from 'axios';
import { Vue, Component, Prop } from 'vue-property-decorator';
import GhButton from '~/patterns/atoms/button/button.vue';
import StateIndicator from '~/patterns/atoms/state-indicator/state-indicator.vue';
import { Location as GreenhomeLocation } from '~/store/location';
import ContentfulPage from '~/api/contentful/page';
import RichTextRenderer from '~/patterns/molecules/rich-text/rich-text.vue';
import slugify from '~/helpers/slugify';
import config from '~/config/config';

@Component({
    components: { GhButton, StateIndicator, RichTextRenderer }
})
export default class ZipcodeEntry extends Vue {
    @Prop() readonly placeholder!: string;
    @Prop() readonly labelPostal: string;
    @Prop() readonly textPrivacyPolicy: string;
    @Prop() readonly stayOnPage: boolean;

    zipcode: string = '';

    errorMessage: string = '';

    state: string = '';

    match?: GreenhomeLocation;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    $gtm: any;

    updateZipcode(event) {
        const zip = event.target.value.replace(' ', '');
        this.zipcode = zip;

        if (zip.match(/^[0-9]{4}[A-z]{2}$/g)) {
            return this.fetchLocation();
        } else if (zip.length < 6) {
            this.state = '';
            this.errorMessage = '';
            this.match = undefined;
        } else {
            this.state = 'error';
            this.errorMessage = 'Een geldige postcode bestaat uit 4 cijfers en 2 letters.';
            this.match = undefined;
        }
        return false;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    fetchLocation(): Promise<any> {
        this.state = 'loading';
        this.errorMessage = '';
        return this.$store
            .dispatch('location/fetch', this.zipcode)
            .then(result => {
                if (window !== undefined) {
                    window.setTimeout(() => {
                        this.state = 'success';
                    }, 500);
                }
                this.match = result;
            })
            .catch(error => {
                this.state = 'error';
                this.match = undefined;
                if (error.response && error.response.status === 404) {
                    this.errorMessage = 'Wij hebben uw postcode helaas niet kunnen vinden.';
                } else {
                    this.errorMessage = 'Er is iets misgegaan. Excuses voor het ongemak.';
                }
            });
    }

    submit() {
        if (!this.match && this.state !== 'error') {
            this.fetchLocation().then(() => this.redirect());
        } else {
            this.redirect();
        }
    }

    redirect() {
        if (this.stayOnPage && this.match && this.match.municipality) {
            const slug = slugify(this.match.municipality.name);
            let path = `/${slug}${this.$route.path}/`;
            this.$store.dispatch('municipality/setSlug', slug);

            if (window.location.search.includes('qrcode')) {
                this.$gtm.pushEvent({
                    eventCategory: 'QRCode',
                    eventAction: 'select',
                    eventLabel: 'municipality',
                    eventValue: this.match.municipality.id
                });
                path += window.location.search;
            }
            this.$router.push(path);
        } else if ((!this.match || this.state === 'error') && !this.stayOnPage) {
            this.$router.push({ path: '/advies/' });
        } else {
            const contentFulPage = new ContentfulPage();

            if (this.match && this.match.municipality.id) {
                contentFulPage
                    .getByMunicipalityId(this.match.municipality.id)
                    .then(entries => {
                        this.$router.replace({ path: `/${entries.items[0].fields.slug}/` });
                        this.$gtm.pushEvent({
                            category: 'Inlog',
                            action: 'Postcode invullen',
                            label: this.zipcode
                        });
                        // Just POST to data lake, not interested in the result
                        axios.post(config.dataLake + this.zipcode);
                    })
                    .catch(() => {
                        this.state = 'error';
                        this.match = undefined;
                        this.errorMessage = 'Er is iets misgegaan. Excuses voor het ongemak.';
                        this.$router.push({ path: '/advies/' });
                    });
            }
        }
    }
}
