




import { Vue, Component, Prop } from 'vue-property-decorator';
import { HasImage } from '~/interfaces/image';
import { getImage } from '~/helpers/image';
import { LinkEntry } from '~/types/Link';
import RichTextRenderer from '~/patterns/molecules/rich-text/rich-text.vue';
import GhLink from '~/patterns/atoms/link/link.vue';
import CloudinaryImage from '~/patterns/atoms/cloudinary-image/cloudinary-image.vue';
import { getPathForEntry } from '~/helpers/routes';

@Component({
    components: { RichTextRenderer, GhLink, CloudinaryImage },
    methods: { getPathForEntry }
})
export default class LinkCard extends Vue {
    @Prop() item!: LinkEntry;
    @Prop() classes: string;

    get image(): HasImage {
        return getImage(this.item.fields, undefined, 'cloudinaryImage');
    }
}
