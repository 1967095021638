










































import { Vue, Component, Prop } from 'vue-property-decorator';
import { EventBus } from '~/helpers/event-bus';

@Component({})
export default class Pagination extends Vue {
    @Prop(String) readonly id!: string;

    @Prop({ type: Number, default: 1 }) currentPage!: number;

    @Prop({ type: Number, default: 1 }) totalPages!: number;

    pagesVisible: number = 3;

    get offset(): number {
        return this.currentPage === 1 ? this.currentPage - 1 : this.currentPage - 2;
    }

    get nextEnabled(): boolean {
        return this.currentPage < this.totalPages;
    }

    get previousEnabled(): boolean {
        return this.currentPage > 1;
    }

    setPage(pageIndex) {
        this.emitAccordionCloseEvent();
        this.$emit('update:currentPage', pageIndex);
        this.$router.push({ query: Object.assign({}, this.$route.query, { page: pageIndex }), hash: this.$route.hash });
    }

    nextPage() {
        if (this.nextEnabled) {
            this.setPage(this.currentPage + 1);
        }
    }

    previousPage() {
        if (this.previousEnabled) {
            this.setPage(this.currentPage - 1);
        }
    }

    goToPage(pageIndex) {
        if (pageIndex >= 1 && pageIndex <= this.totalPages) {
            this.setPage(pageIndex);
        }
    }

    emitAccordionCloseEvent() {
        if (this.id) {
            EventBus.$emit('accordion-close', this.id);
        }
    }

    mounted() {
        EventBus.$on('filterChanged', () => {
            this.setPage(1);
        });
    }

    beforeDestroy() {
        EventBus.$off('filterChanged');
    }
}
