import Vue from 'vue';

export interface ScrollToElementFunc {
    (element: Element): void;
}

export const scrollToElement: ScrollToElementFunc = function(this: Vue, element: Element): void {
    this.$root.$data.programmaticScroll = true;

    this.$nextTick((): void => {
        // Don't use smooth scrolling as it will cause multiple scroll events and the menu bar will then appear :(
        element.scrollIntoView();
    });
};

Vue.prototype.$scrollToElement = scrollToElement;

