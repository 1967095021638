




import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { format, parseISO } from 'date-fns';
import nlLocale from 'date-fns/locale/nl';
import { EventCollectionFields } from '~/types/EventCollection';
import { MunicipalityType } from '~/types/Municipality';
import { InDeBuurtEntry } from '~/types/InDeBuurt';
import { PaginationProperties } from '~/interfaces/pagination';
import { getImage } from '~/helpers/image';
import { HasImage } from '~/interfaces/image';

import Card from '~/patterns/molecules/_cards/card/card.vue';
import CloudinaryImage from '~/patterns/atoms/cloudinary-image/cloudinary-image.vue';
import Pagination from '~/patterns/molecules/pagination/pagination.vue';
import RichText from '~/patterns/molecules/rich-text/rich-text.vue';

// An event collection can by one of these three types
// Based on the type of collection it gets different content
export const TYPE_PROJECT = 'Project';
export const TYPE_EVENT = 'Event';
export const TYPE_INITIATIVE = 'Initiative';
export const TYPE_ACTION = [TYPE_PROJECT, TYPE_EVENT];

@Component({
    name: 'event-collection',
    components: { RichText, Card, Pagination, CloudinaryImage }
})
export default class EventCollection extends Vue {
    @Prop() readonly fields!: EventCollectionFields;

    perPage: number;

    currentPage: number;

    constructor() {
        super();
        this.perPage = 6;
        this.currentPage = 1;
    }

    get municipality(): MunicipalityType {
        return this.$store.getters['municipality/municipality'];
    }

    get paginationProperties(): PaginationProperties {
        return {
            pageNumber: this.currentPage,
            perPage: this.perPage
        };
    }

    get totalPages(): number {
        return this.$store.getters[`indebuurt/${this.getType()}sTotalPages`];
    }

    get cards(): InDeBuurtEntry | void {
        return this.$store.getters[`indebuurt/${this.getType()}s`];
    }

    getType(): string {
        return this.fields.types.toLowerCase() === ('project' || 'event') ? 'combinedAction' : this.fields.types.toLowerCase();
    }

    getDate(item) {
        const { hideDate, startTimestamp, endTimestamp } = item.fields;
        if (hideDate) {
            return '';
        }

        let date = '';
        if (startTimestamp) {
            date = format(parseISO(startTimestamp), 'd MMMM yyyy', { locale: nlLocale });
        }
        if (
            startTimestamp &&
            endTimestamp &&
            date !== format(parseISO(endTimestamp), 'd MMMM yyyy', { locale: nlLocale })
        ) {
            date =
                format(parseISO(startTimestamp), 'd MMM yyyy', { locale: nlLocale }) +
                ' - ' +
                format(parseISO(endTimestamp), 'd MMM yyyy', { locale: nlLocale });
        }
        return date;
    }

    getImage(item): HasImage {
        return getImage(item.fields);
    }

    loadCardsFromStore() {
        const payload = {
            paginationProperties: this.paginationProperties,
            municipality: this.municipality,
            type: this.getType()
        };
        return this.$store.dispatch('indebuurt/loadpage', payload);
    }

    loadCardsAndParams() {
        if (this.$route.query && this.$route.query.page) {
            this.currentPage = Number(this.$route.query.page);
        }
        return this.loadCardsFromStore();
    }

    serverPrefetch() {
        if (!this.cards) {
            return this.loadCardsAndParams();
        }
        return undefined;
    }

    mounted() {
        if (!this.cards) {
            this.loadCardsAndParams();
        }
    }

    @Watch('municipality', { immediate: true })
    @Watch('paginationProperties', {})
    onPageChange() {
        this.loadCardsFromStore();
    }
}
