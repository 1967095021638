



















import { Vue, Component, Prop } from 'vue-property-decorator';
import GhLink from '~/patterns/atoms/link/link.vue';

export interface LinkInList {
    text: string;
    url: string;
    iconClass: string;
    external?: boolean;
}

@Component({
    components: { GhLink }
})
export default class LinksList extends Vue {
    @Prop(String) readonly classes!: string;

    @Prop(String) readonly linkClasses!: string;

    @Prop({ type: Boolean, default: false }) readonly vertical!: boolean;

    @Prop({ type: Array, default: [] }) readonly links!: LinkInList[];
}
