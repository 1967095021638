
































import { Vue, Component, Prop } from 'vue-property-decorator';
import RichTextRenderer from 'contentful-rich-text-vue-renderer';
import { INLINES, BLOCKS, MARKS } from '@contentful/rich-text-types';
import InlineEntry from '~/patterns/organisms/_inlines/inline-entry/inline-entry.vue';
import EmbeddedEntry from '~/patterns/organisms/_collections/embedded-entry/embedded-entry.vue';
import CustomRichfulList from '~/patterns/organisms/_collections/custom-richful/custom-richful-list.vue';
import GhHeading from '~/patterns/atoms/heading.vue';
import CustomMark from '~/patterns/atoms/richtext/custom-mark.vue';

// Use external variables instead of component data to prevent re-rendering loop
let level = 1;
let heading = 'heading-1';
let base = 1;

/* eslint-disable @typescript-eslint/no-explicit-any */
const getTextContent = (content: any[]) => {
    return content.reduce((acc: string, data: any) => {
        if (data.content && Array.isArray(data.content)) {
            acc += getTextContent(data.content);
        } else {
            acc += data.value;
        }
        return acc;
    }, '');
};
/* eslint-enable @typescript-eslint/no-explicit-any */

@Component({
    name: 'rich-text',
    components: { RichTextRenderer, CustomMark }
})
export default class RichText extends Vue {
    @Prop() readonly document;
    @Prop() readonly customClass: string;
    @Prop() readonly customTitle: string;

    @Prop({ type: Boolean, default: false }) readonly textOnly;

    renderNode = {
        [INLINES.EMBEDDED_ENTRY]: (node, key, createElement) => {
            return createElement(InlineEntry, { props: { key, node } });
        },
        [INLINES.ENTRY_HYPERLINK]: (node, key, createElement) => {
            return createElement(InlineEntry, { props: { key, node } });
        },
        [INLINES.HYPERLINK]: (node, key, createElement) => {
            return createElement(InlineEntry, { props: { key, node } });
        },
        [BLOCKS.EMBEDDED_ENTRY]: (node, key, createElement) => {
            return createElement(EmbeddedEntry, { props: { key, node } });
        },
        [BLOCKS.UL_LIST]: (node, key, createElement) => {
            return createElement(CustomRichfulList, {
                props: { key, node, customClass: this.customClass, customTitle: this.customTitle }
            });
        },
        [BLOCKS.HEADING_2]: (node, key, createElement) => {
            return this.createHeading(node, key, createElement);
        },
        [BLOCKS.HEADING_3]: (node, key, createElement) => {
            return this.createHeading(node, key, createElement);
        },
        [BLOCKS.HEADING_4]: (node, key, createElement) => {
            return this.createHeading(node, key, createElement);
        },
        [BLOCKS.HEADING_5]: (node, key, createElement) => {
            return this.createHeading(node, key, createElement);
        },
        [BLOCKS.HEADING_6]: (node, key, createElement) => {
            return this.createHeading(node, key, createElement);
        }
    };

    renderMarks = {
        [MARKS.BOLD]: (text, key, h) => {
            return h('custom-mark', { key, props: { text: text, type: 'bold' } }, text);
        },
        [MARKS.ITALIC]: (text, key, h) => {
            return h('custom-mark', { key, props: { text: text, type: 'italic' } }, text);
        }
    };

    // Create heading tags based on semantic structure
    createHeading(node, key, createElement) {
        const nodeLevel = Number(node.nodeType.replace('heading-', ''));

        if (node.nodeType < heading) {
            level--;
        } else if (node.nodeType > heading) {
            level++;
        }
        heading = node.nodeType;

        if (nodeLevel < level) {
            // Use the highest level heading possible
            level = nodeLevel > base ? nodeLevel : base;
        }

        return createElement(GhHeading, { props: { key, node, level } });
    }

    get plainText(): string {
        return getTextContent(this.document.content);
    }

    created() {
        // Find level of first heading in document
        const node = this.document.content[0];
        if (node && node.nodeType.startsWith) {
            level = Number(node.nodeType.replace('heading-', ''));
            heading = node.nodeType;
            base = level;
        }
    }
}
