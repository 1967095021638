




import { Vue, Component, Watch } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import { throttle } from 'lodash';
import { MunicipalityFields } from '~/types/Municipality';
import Logo from '~/patterns/atoms/logo/logo.vue';
import Hamburger from '~/patterns/atoms/hamburger/hamburger.vue';
import Modal from '~/patterns/organisms/modal/modal.vue';
import ModalOpener from '~/patterns/molecules/modal-opener/modal-opener.vue';
import LinkList from '~/patterns/molecules/link-list/link-list.vue';
import GhButton from '~/patterns/atoms/button/button.vue';
import MunicipalityLogo from '~/patterns/atoms/municipality-logo/municipality-logo.vue';
import GhLink from '~/patterns/atoms/link/link.vue';
import { EventBus } from '~/helpers/event-bus';
import NavPushDown from '~/patterns/atoms/nav-push-down/nav-push-down.vue';

@Component({
    components: { Logo, Hamburger, Modal, ModalOpener, LinkList, GhButton, GhLink, MunicipalityLogo, NavPushDown },

    computed: {
        ...mapGetters({ site: 'site/site' })
    }
})
export default class Navigation extends Vue {
    lastScrollTop: number;

    foldNavigation: boolean = false;
    scrolledDownDuration: number;

    minimalScrollForFold: number;

    site: string;

    constructor() {
        super();
        this.lastScrollTop = 0;
        this.minimalScrollForFold = 110;
        this.scrolledDownDuration = 0;
    }

    get extraClasses(): string {
        const classes = [`rel-${this.site}`];
        if (this.foldNavigation) {
            classes.push('navigation--folded');
        }
        if (this.lastScrollTop > 0) {
            classes.push('navigation-shadow');
        }
        return classes.join(' ');
    }

    get primaryLinks() {
        if (this.site === 'zakelijk') {
            return [
                {
                    text: 'Energieloket',
                    // iconClass: 'fa fa-globe-americas',
                    url: this.hasMunicipality ? `/${this.municipality.slug}` : '/',
                    exact: true
                },
                {
                    text: 'Sectoren',
                    // iconClass: 'fa fa-bolt',
                    url: this.hasMunicipality ? `${this.municipality.slug}/sectoren` : '/sectoren'
                },
                {
                    text: 'Partijen',
                    // iconClass: 'fa fa-location-arrow',
                    url: this.hasMunicipality ? `${this.municipality.slug}/partijen` : '/partijen'
                },
                {
                    text: 'Subsidies',
                    // iconClass: 'fa fa-euro-sign',
                    url: this.hasMunicipality ? `${this.municipality.slug}/subsidies` : '/subsidies'
                },
                {
                    text: 'Vakspecialisten',
                    // iconClass: 'fa fa-wrench',
                    url: this.hasMunicipality ? `${this.municipality.slug}/vakspecialisten` : '/vakspecialisten'
                }
            ];
        }

        return [
            {
                text: 'Energieloket',
                // iconClass: 'fa fa-globe-americas',
                url: this.hasMunicipality ? `/${this.municipality.slug}` : '/',
                exact: true
            },
            {
                text: 'Energie Besparen',
                // iconClass: 'fa fa-bolt',
                url: this.hasMunicipality ? `${this.municipality.slug}/energiebesparen` : '/energiebesparen'
            },
            {
                text: 'In de Buurt',
                // iconClass: 'fa fa-location-arrow',
                url: this.hasMunicipality ? `${this.municipality.slug}/indebuurt` : '/indebuurt'
            },
            {
                text: 'Subsidies',
                // iconClass: 'fa fa-euro-sign',
                url: this.hasMunicipality ? `${this.municipality.slug}/subsidies` : '/subsidies'
            },
            {
                text: 'Vakspecialisten',
                // iconClass: 'fa fa-wrench',
                url: this.hasMunicipality ? `${this.municipality.slug}/vakspecialisten` : '/vakspecialisten'
            }
        ];
    }

    get secondaryLinks() {
        const particulier = {
            text: 'Particulier',
            iconClass: '',
            isActive: false,
            url:
                this.hasMunicipality && this.municipality.relDomain?.includes('particulier')
                    ? `https://regionaalenergieloket.nl/${this.municipality.slug}/`
                    : 'https://regionaalenergieloket.nl/'
        };

        const zakelijk = {
            text: 'Zakelijk',
            iconClass: '',
            isActive: false,
            url:
                this.hasMunicipality && this.municipality.relDomain?.includes('zakelijk')
                    ? `https://zakelijk.regionaalenergieloket.nl/${this.municipality.slug}/`
                    : 'https://zakelijk.regionaalenergieloket.nl/'
        };

        const vve = {
            text: 'VvE',
            iconClass: '',
            isActive: false,
            url:
                this.hasMunicipality && this.municipality.relDomain?.includes('vve')
                    ? `https://vve.regionaalenergieloket.nl/${this.municipality.slug}/`
                    : 'https://vve.regionaalenergieloket.nl/'
        };

        if (this.site === 'particulier') {
            particulier.url = '/';
            particulier.isActive = true;
        }

        if (this.site === 'zakelijk') {
            zakelijk.url = '/';
            zakelijk.isActive = true;
        }

        if (this.site === 'vve') {
            vve.url = '/';
            vve.isActive = true;
        }

        if (this.hasMunicipality) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const links = [] as any[];
            if (this.municipality?.relDomain?.includes('particulier')) {
                links.push(particulier);
            }
            if (this.municipality?.relDomain?.includes('zakelijk')) {
                links.push(zakelijk);
            }
            if (this.municipality?.relDomain?.includes('vve')) {
                links.push(vve);
            }
            return links;
        }

        return [particulier, zakelijk, vve];
    }

    get showSecondaryLinks(): boolean {
        return this.$store.getters['navigation/showSecondaryLinks'];
    }

    get municipality(): MunicipalityFields {
        return this.$store.getters['municipality/fields'];
    }

    get showLogo(): boolean {
        return !this.municipality?.hideLogo;
    }

    get hasMunicipality() {
        return !!this.municipality;
    }

    get homeUrl() {
        return this.hasMunicipality && this.municipality.slug ? `/${this.municipality.slug}` : '/';
    }

    get hasLogo(): boolean {
        if (this.municipality) {
            return !!this.municipality.name;
        } else {
            return false;
        }
    }

    // NuxtLink does not support 'aria-current' in our version yet, so we have to do it the 'non Vue' way.
    @Watch('$route')
    onRouteChanged(val, oldVal) {
        const oldCurrent = document.querySelector(`.navigation__primary .link-list__link[href="${oldVal.fullPath}"]`);
        const newCurrent = document.querySelector(`.navigation__primary .link-list__link[href="${val.fullPath}"]`);

        oldCurrent && oldCurrent.removeAttribute('aria-current');
        newCurrent && newCurrent.setAttribute('aria-current', 'page');
    }

    openHuisscanModal() {
        this.$store.dispatch('modals/setExternalUrl', 'https://huisscan.regionaalenergieloket.nl');
        EventBus.$emit('openModal', { id: 'external-link-modal' });
    }

    get calculateShowSecondaryNav() {
        const relDomain = this.municipality && this.municipality.relDomain ? this.municipality.relDomain : [];
        const showSecondaryLinks = this.hasMunicipality && relDomain.filter(item => item !== this.site).length;
        return showSecondaryLinks || (!this.hasMunicipality && this.$route.path === '/');
    }

    @Watch('calculateShowSecondaryNav')
    onShowSecondaryChanged(val) {
        this.$store.dispatch('navigation/setShowSecondaryLinks', val);
    }

    handleScroll() {
        const currentScrollPosition = window.pageYOffset || window.scrollY;
        const threshold: number = 5;
        const lastScrollTop = this.lastScrollTop;
        this.lastScrollTop = currentScrollPosition;

        if (this.$root.$data.programmaticScroll !== true) {
            if (currentScrollPosition > this.minimalScrollForFold && currentScrollPosition > lastScrollTop) {
                this.scrolledDownDuration++;
            } else {
                this.scrolledDownDuration = 0;
                this.foldNavigation = false;

                const showSecondaryLinks = this.calculateShowSecondaryNav && this.lastScrollTop < 110;
                this.$store.dispatch('navigation/setShowSecondaryLinks', showSecondaryLinks);
            }

            if (this.scrolledDownDuration > threshold) {
                this.foldNavigation = true;
                this.$store.dispatch('navigation/setShowSecondaryLinks', false);
            }
        }

        if (this.$root.$data.programmaticScroll === true) {
            this.$root.$data.programmaticScroll = false;
        }
    }

    beforeMount() {
        window.addEventListener(
            'scroll',
            throttle(this.handleScroll, 100, {
                leading: true
            })
        );
    }

    mounted() {
        this.$store.dispatch('navigation/setShowSecondaryLinks', this.calculateShowSecondaryNav);
    }

    beforeDestroy() {
        window.removeEventListener(
            'scroll',
            throttle(this.handleScroll, 100, {
                leading: true
            })
        );
    }
}
