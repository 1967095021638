









import { Vue, Component, Prop } from 'vue-property-decorator';
import { Block } from '@contentful/rich-text-types';

@Component({
    components: {
        ContentBlocks: () => import('~/patterns/organisms/_collections/content-blocks/content-blocks.vue'),
        RichText: () => import('~/patterns/molecules/rich-text/rich-text.vue')
    }
})
export default class EmbeddedEntry extends Vue {
    @Prop() node!: Block;
    @Prop() readonly customClass: string;
    @Prop() readonly customTitle: string;
}
