


































































































import { Vue, Component, Prop } from 'vue-property-decorator';
import { throttle } from 'lodash';
import { EventBus } from '~/helpers/event-bus';

@Component({})
export default class AccordionItem extends Vue {
    @Prop(String) readonly id!: string;

    @Prop({ type: Boolean, default: null }) isOpen: boolean | null;

    internalOpen: boolean | null = null; // internal-tracking in case the optional isOpen prop is not passed.

    toggleItem() {
        // Toggle the internal state only if a parent isn't controlling it through a prop
        if (this.isOpen === null) {
            this.internalOpen = !this.internalOpen;
        }
    }

    openAccordion(el) {
        if (el) {
            if (!el.style) {
                // Use extra height for Safari and Edge
                el.style = { maxHeight: el.scrollHeight * 2 + 'px' };
            } else {
                el.style.maxHeight = el.scrollHeight * 2 + 'px';
            }
        }
    }

    closeAccordion(el) {
        if (el) {
            if (!el.style) {
                el.style = { maxHeight: '0px' };
            } else {
                el.style.maxHeight = '0px';
            }
        }
    }

    recalculateHeight() {
        this.openAccordion(this.$refs.collapsable);
    }

    closeHandler(id) {
        if (id === this.id) {
            if (this.isOpen !== null) {
                this.$emit('update:isOpen', false);
            } else {
                this.internalOpen = false;
            }
        }
    }

    beforeMount() {
        window.addEventListener('resize', throttle(this.recalculateHeight, 200));

        if (this.id) {
            EventBus.$on('accordion-close', this.closeHandler);
        }
    }

    beforeDestroy() {
        window.removeEventListener('resize', throttle(this.recalculateHeight, 200));

        if (this.id) {
            EventBus.$off('accordion-close', this.closeHandler);
        }
    }
}
