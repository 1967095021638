




import { Vue, Component, Prop } from 'vue-property-decorator';
import { MunicipalityFields } from '~/types/Municipality';
import { HasImage } from '~/interfaces/image';
import { getImage } from '~/helpers/image';
import { CtaBannerFields } from '~/types/CtaBanner';

import CloudinaryImage from '~/patterns/atoms/cloudinary-image/cloudinary-image.vue';
import ContentfulButton from '~/patterns/molecules/contentful-button.vue';
import GhButton from '~/patterns/atoms/button/button.vue';
import GhLink from '~/patterns/atoms/link/link.vue';
import RichTextRenderer from '~/patterns/molecules/rich-text/rich-text.vue';

import { getPathForEntry } from '~/helpers/routes';

@Component({
    components: { RichTextRenderer, GhButton, GhLink, CloudinaryImage, ContentfulKnop: ContentfulButton },
    methods: { getPathForEntry }
})
export default class CtaBanner extends Vue {
    @Prop() readonly fields!: CtaBannerFields;

    @Prop() readonly municipality!: MunicipalityFields;

    get modifierClass() {
        if (this.fields && this.fields.blueBackgroundColor) {
            return 'background background--primary-lighter';
        }
        return 'background background--secondary-lighter';
    }

    get buttonModifier() {
        if (this.fields && this.fields.blueBackgroundColor) {
            return 'btn--primary';
        }
        return 'btn--secondary';
    }

    get municipalityName(): string {
        if (this.municipality) {
            return this.municipality.name;
        }
        return '';
    }

    get showScans(): boolean {
        return !!this.fields && this.fields.showHouseScansCount && !!this.municipalityName;
    }

    get image(): HasImage {
        return getImage(this.fields as CtaBannerFields);
    }

    get showImage(): boolean {
        return !!this.image && ((!!this.image.image && !!this.image.image.url) || !!this.image.cloudinaryImage);
    }
}
