



















import { Component, Prop, Vue } from 'vue-property-decorator';

import GhLink from '~/patterns/atoms/link/link.vue';
import GhButton from '~/patterns/atoms/button/button.vue';

import { KnopType, TypeButton } from '~/types/Knop';
import { getPathForEntry } from '~/helpers/routes';
import EventBus from '~/helpers/event-bus';

@Component({
    components: { GhLink, GhButton }
})
export default class ContentfulButton extends Vue {
    @Prop({ required: true })
    entry: KnopType;

    get targetUrl(): string | undefined {
        if (this.entry?.fields?.destination) {
            return getPathForEntry(this.entry.fields.destination);
        }
        return undefined;
    }

    openIframeModal() {
        this.$store.dispatch('modals/setExternalUrl', this.targetUrl);
        EventBus.$emit('openModal', { id: 'dynamic-iframe-modal' });
    }

    openExternalLinkModal(event) {
        event.preventDefault();
        this.$store.dispatch('modals/setExternalUrl', this.targetUrl);
        EventBus.$emit('openModal', { id: 'external-link-modal' });
    }

    get typeButton() {
        const typeButton = this.entry.fields.typeButton;

        switch (typeButton) {
            case TypeButton.Primary:
                return 'btn btn--primary';
            case TypeButton.Secondary:
                return 'btn btn--secondary';
            case TypeButton.Tertiary:
                return 'btn btn--tertiary';
            default:
                return 'btn btn--primary';
        }
    }

    get show(): boolean {
        const showOn = this.entry.fields?.showOn || null;
        const showUntil = this.entry.fields?.showUntil || null;
        const from = new Date(showOn || Date.now()); // To prevent invalid date
        const until = new Date(showUntil || Date.now());
        const now = new Date();

        return !!(
            (!showOn && !showUntil) ||
            (showOn && !showUntil && from < now) ||
            (showUntil && !showOn && until > now) ||
            (showOn && showUntil && from < now && until > now)
        );
    }
}
