



















import { Vue, Component } from 'vue-property-decorator';
import { get as getCookie, set as setCookie } from 'js-cookie';

import EventBus from '~/helpers/event-bus';
import { cookieName } from '~/store/municipality';
import { Entry } from '~/node_modules/contentful';
import MunicipalityBanner from '~/api/contentful/banner';
import { MunicipalityFields } from '~/types/Municipality';
import { MunicipalityBannerEntry } from '~/types/MunicipalityBanner';

import Logo from '~/patterns/atoms/logo/logo.vue';
import Modal from '~/patterns/organisms/modal/modal.vue';
import ZipcodeEntry from '~/patterns/molecules/zipcode-entry/zipcode-entry.vue';

@Component({
    components: { Modal, ZipcodeEntry, Logo }
})
export default class PostalModal extends Vue {
    entry: Entry<MunicipalityBannerEntry> | undefined;

    async created() {
        const noPopupRoutes = ['/', '/acties/', ...this.municipalitySlugs];
        const banners = await new MunicipalityBanner().getByName('Popup gemeente zoeker');
        this.entry = banners.items[0];

        if (
            !this.checkMunicipality &&
            !noPopupRoutes.includes(this.$nuxt.$route.path) &&
            !getCookie('zipcode-popup-dismissed')
        ) {
            setTimeout(() => {
                EventBus.$emit('openModal', { id: 'modal-postcode' });
            }, 2000);
        }
    }

    get municipalitySlugs(): string[] {
        return Object.keys(this.$store.getters['municipality/all']).map(slug => `/${slug}/`);
    }

    get checkMunicipality(): boolean {
        const municipalityFields: MunicipalityFields = this.$store.getters['municipality/fields'];

        return !!municipalityFields || !!getCookie(cookieName);
    }

    closeHandler() {
        setCookie('zipcode-popup-dismissed', 1, { expires: 30 });
    }
}
