















































import { Vue, Component, Watch } from 'vue-property-decorator';
import FocusLock from 'vue-focus-lock';
import Logo from '~/patterns/atoms/logo/logo.vue';
import eventBus from '~/helpers/event-bus';

export const TYPE_NAVIGATION = 'navigation';

@Component({
    components: { Logo, FocusLock },
    props: {
        id: String,
        type: String
    }
})
export default class Modal extends Vue {
    open: boolean = false;

    @Watch('$route')
    onRouteChange() {
        this.open = false;
        this.$store.dispatch('modals/remove', this.$props.id);
    }

    get isNavigation() {
        return this.$props.type === TYPE_NAVIGATION;
    }

    toggleModal() {
        this.open = !this.open;
        if (!this.open) {
            this.$emit('closed', true);
            this.$store.dispatch('modals/remove', this.$props.id);
        }
    }

    openHandler({ id }) {
        if (id === this.$props.id) {
            this.open = true;
            this.$store.dispatch('modals/add', this.$props.id);
        }
    }

    closeHandler({ id }) {
        if (id === this.$props.id) {
            this.open = false;
            this.$store.dispatch('modals/remove', this.$props.id);
        }
    }

    mounted() {
        eventBus.$on('openModal', this.openHandler);
        eventBus.$on('closeModal', this.closeHandler);
    }

    beforeDestroy() {
        // Remove listeners in order to avoid duplicate events
        eventBus.$off('openModal', this.openHandler);
        eventBus.$off('closeModal', this.closeHandler);
    }
}
