




import { Vue, Component, Prop } from 'vue-property-decorator';
import { ContentBlockFields } from '~/types/ContentBlocks';
import { HasImage } from '~/interfaces/image';
import { getImage } from '~/helpers/image';
import slugify from '~/helpers/slugify';

import CloudinaryImage from '~/patterns/atoms/cloudinary-image/cloudinary-image.vue';
import FlexibleGridBlock from '~/patterns/organisms/_content-blocks/flexible-grid-block/flexible-grid-block.vue';
import RichTextRenderer from '~/patterns/molecules/rich-text/rich-text.vue';

@Component({
    components: { RichTextRenderer, FlexibleGridBlock, CloudinaryImage }
})
export default class ContactArea extends Vue {
    @Prop() readonly fields!: ContentBlockFields;

    get image(): HasImage {
        return getImage(this.fields as ContentBlockFields);
    }

    get slug(): string {
        return slugify(this.fields.name) || '';
    }
}
