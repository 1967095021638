
















import { Component, Vue, Prop } from 'vue-property-decorator';
import GhButton from '~/patterns/atoms/button/button.vue';

@Component({
    components: { GhButton }
})
export default class ActiveFilters extends Vue {
    @Prop({ required: true }) storeModule: string;

    get activeFilters() {
        return this.$store.getters[`${this.storeModule}/activeFilters`].filter(filter => filter.showButton);
    }

    handleClick(filter, option) {
        this.$store.dispatch(`${this.storeModule}/removeActiveOptionFromFilter`, { filter, option });
    }
}
