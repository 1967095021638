












import { Vue, Component, Prop } from 'vue-property-decorator';

import Stars from '~/patterns/atoms/stars.vue';

import { ReviewFields } from '~/types/Review';
import { generateSlugForEntry } from '~/helpers/routes';

@Component({
    components: { Stars }
})
export default class Review extends Vue {
    @Prop() readonly entry!: ReviewFields;

    handleClick() {
        if (this.entry && this.entry.linkTo) {
            const slug = generateSlugForEntry(this.entry.linkTo);
            if (slug) {
                this.$router.push(slug);
            }
        }
    }
}
