



















import { Vue, Component } from 'vue-property-decorator';
import Modal from '~/patterns/organisms/modal/modal.vue';
import GhButton from '~/patterns/atoms/button/button.vue';
import { EventBus } from '~/helpers/event-bus';

@Component({
    components: { Modal, GhButton }
})
export default class ExternalLinkModal extends Vue {
    open() {
        const url = this.$store.getters['modals/currentLinkSrc'];
        window.open(url, '_blank');
        this.close();
    }

    close() {
        EventBus.$emit('closeModal', { id: 'external-link-modal' });
    }
}
