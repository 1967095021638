



























import { Vue, Component, Prop } from 'vue-property-decorator';
import { ProductSolutionFields } from '~/types/Product';
import { HasImage } from '~/interfaces/image';
import { getImage } from '~/helpers/image';
import slugify from '~/helpers/slugify';

import CloudinaryImage from '~/patterns/atoms/cloudinary-image/cloudinary-image.vue';
import RichTextRenderer from '~/patterns/molecules/rich-text/rich-text.vue';

@Component({
    components: { RichTextRenderer, CloudinaryImage }
})
export default class SolutionOpportunity extends Vue {
    @Prop() readonly fields!: ProductSolutionFields;

    @Prop() readonly classes?: string;

    get image(): HasImage {
        return getImage(this.fields as ProductSolutionFields);
    }

    get slug(): string {
        return slugify(this.fields.name) || '';
    }
}
