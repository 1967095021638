import { Context } from '@nuxt/types';
import VueRouter from 'vue-router/types';
import config, { env } from '../../config/config';
import { initAnalytics } from './google-analytics';

declare global {
    interface Window {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        ga: any;
    }
}

const { gaKey, gaAggKey } = config;

const tagManager = {
    env,
    gaKey,
    gaAggKey,
    initAnalytics,

    pushEvent(event): void {
        if (env === 'production' && window && window.ga) {
            window.ga('send', 'event', ...Object.values(event));
            window.ga('aggregatedTracker.send', 'event', ...Object.values(event));
        } else {
            // eslint-disable-next-line no-console
            console.log('send', 'event', ...Object.values(event));
        }
    },

    init(ctx: Context): void {
        if (env === 'production') {
            this.initAnalytics(this.gaKey, this.gaAggKey);
        }

        if (ctx.app && ctx.app.router) {
            this.initPageTracking(ctx.app.router);
        }

        if (window) {
            this.initCustomEvents();
        }
    },

    initPageTracking(router: VueRouter): void {
        router.afterEach((to): void => {
            setTimeout((): void => {
                if (window && window.ga) {
                    window.ga('send', 'pageview', to.fullPath);
                    window.ga('aggregatedTracker.send', 'pageview', to.fullPath);
                } else {
                    // eslint-disable-next-line no-console
                    console.log('send:pageview:', to.fullPath);
                }
            }, 0);
        });
    },

    initCustomEvents(): void {
        window.onload = (): void => {
            if (window.location.pathname.includes('vakspecialisten')) {
                const button = document.querySelector('[aria-labelledby="website"] > a');
                if (button) {
                    button.addEventListener('click', (): void => {
                        this.pushEvent({ category: 'Offerte', action: 'website aanbieder bezocht' });
                    });
                }
            }
        };
    }
};

export default function(ctx: Context, inject): void {
    tagManager.init(ctx);
    inject('gtm', tagManager);
}
