






































































import { Vue, Component } from 'vue-property-decorator';

@Component({})
export default class Accordion extends Vue {
    // The accordion works together with the "accordion-item" component
    // To see how to use the accordion, check out the footer
}
