





















import { mixins } from 'vue-class-component';
import { Component, Prop } from 'vue-property-decorator';
import RichTextRenderer from '~/patterns/molecules/rich-text/rich-text.vue';
import Accordion from '~/patterns/organisms/accordion/accordion.vue';
import AccordionItem from '~/patterns/molecules/accordion-item/accordion-item.vue';
import { FaqFields } from '~/types/Faq';
import DisplayLogicMixin from '~/mixins/DisplayLogic.vue';

@Component({
    components: { Accordion, AccordionItem, RichTextRenderer }
})
export default class FAQ extends mixins(DisplayLogicMixin) {
    @Prop() readonly fields!: FaqFields;
}
