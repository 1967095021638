




import { mixins } from 'vue-class-component';
import { Component, Prop } from 'vue-property-decorator';
import { MunicipalityType } from '~/types/Municipality';
import { FlexibleGridBlockFields } from '~/types/FlexibleGridBlock';
import DisplayLogicMixin from '~/mixins/DisplayLogic.vue';
import slugify from '~/helpers/slugify';

@Component({
    components: {}
})
export default class FlexibleGridBlock extends mixins(DisplayLogicMixin)  {
    @Prop() readonly fields!: FlexibleGridBlockFields;

    get display(): boolean {
        if (!this.fields.municipalities) {
            return true;
        }
        const codes = this.fields.municipalities.map((item: MunicipalityType) => item.fields.municipality);
        return !!this.municipality?.fields && codes.includes(this.municipality.fields.municipality);
    }

    get hasTitle(): boolean {
        return this.fields && !!this.fields.title;
    }

    get hasIntro(): boolean {
        return this.fields && !!this.fields.intro;
    }

    hasColumn(fieldName: string): boolean {
        return (this.fields && !!this.fields[fieldName]) || !!this.$slots[fieldName];
    }

    get maxColumnCount(): number {
        const columnNames: string[] = ['left', 'right', 'column3'];
        let amountWithValue: number = 0;
        for (let i = 0; i < columnNames.length; i++) {
            this.hasColumn(columnNames[i]) && amountWithValue++;
        }
        return amountWithValue;
    }

    get modifiers(): string {
        let modifiers = '';

        if (this.fields) {
            if (this.fields.verticalAlignment === 'Midden') {
                modifiers = 'flexible-grid-block--middle-align';
            } else if (this.fields.verticalAlignment === 'Onder') {
                modifiers = 'flexible-grid-block--bottom-align';
            }
            if (this.fields.horizontalAlignment === 'Midden') {
                modifiers += ' text--center';
            } else if (this.fields.horizontalAlignment === 'Rechts') {
                modifiers += ' text--right';
            }
            if (this.fields.backgroundColor) {
                modifiers += ' background background--gray background--bleed';
            }
        }
        return modifiers;
    }

    get modifierPrimary(): string {
        let modifiers = '';
        if (this.fields) {
            if (this.fields.primaryHorizontalAlignment === 'Midden') {
                modifiers = 'text--center';
            } else if (this.fields.primaryHorizontalAlignment === 'Rechts') {
                modifiers = 'text--right';
            }
        }
        return modifiers;
    }

    get modifierSecondary(): string {
        let modifiers = '';
        if (this.fields) {
            if (this.fields.secondaryHorizontalAlignment === 'Midden') {
                modifiers = 'text--center';
            } else if (this.fields.secondaryHorizontalAlignment === 'Rechts') {
                modifiers = 'text--right';
            }
        }
        return modifiers;
    }

    get modifierTertiary(): string {
        let modifiers = '';
        if (this.fields) {
            if (this.fields.secondaryHorizontalAlignment === 'Midden') {
                modifiers = 'text--center';
            } else if (this.fields.secondaryHorizontalAlignment === 'Rechts') {
                modifiers = 'text--right';
            }
        }
        return modifiers;
    }

    get slug(): string | null {
        if (!this.fields) {
            return null;
        }
        const { name, title } = this.fields;
        return name ? slugify(name) : slugify(title);
    }
}
