




import { Vue, Component, Prop } from 'vue-property-decorator';
import { format, parseISO } from 'date-fns';
import nlLocale from 'date-fns/locale/nl';
import { ProductSolutionEntry } from '~/types/Product';
import { InDeBuurtEntry } from '~/types/InDeBuurt';
import { HasImage } from '~/interfaces/image';
import { getImage } from '~/helpers/image';
import { getPathForEntry } from '~/helpers/routes';

import CloudinaryImage from '~/patterns/atoms/cloudinary-image/cloudinary-image.vue';
import GhLink from '~/patterns/atoms/link/link.vue';
import RichTextRenderer from '~/patterns/molecules/rich-text/rich-text.vue';

@Component({
    components: { RichTextRenderer, GhLink, CloudinaryImage },
    methods: { getPathForEntry }
})
export default class Card extends Vue {
    @Prop() item!: ProductSolutionEntry | InDeBuurtEntry;

    @Prop() readonly type!: string;

    get iconPath(): string | undefined {
        if (this.item.fields && 'icon' in this.item.fields && this.item.fields.icon) {
            return require(`~/assets/images/icons/${this.item.fields.icon}.svg`);
        }
        return undefined;
    }

    get modifiers(): string {
        const modifiers: string[] = [];
        if (this.isIndeBuurtEntry(this.item)) {
            modifiers.push('card--sm');
        }
        if (this.type === 'solution') {
            modifiers.push('card--solution');
        }
        return modifiers.join(' ');
    }

    get date(): string {
        if(this.isIndeBuurtEntry(this.item) && this.item.fields.startTimestamp && !this.item.fields.hideDate) {
            return format(parseISO(this.item.fields.startTimestamp), 'd MMMM yyyy', { locale: nlLocale });
        }
        return '';
    }

    get image(): HasImage {
        return getImage(this.item.fields);
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    isIndeBuurtEntry(item: any): item is InDeBuurtEntry {
        return !!(item as InDeBuurtEntry);
    }
}
