




import { Vue, Component, Prop } from 'vue-property-decorator';
import { MunicipalityType } from '~/types/Municipality';
import { TagType } from '~/types/Tag';
import NewsCard from '~/patterns/molecules/_cards/news/news.vue';

const Carousel = () => import(/* webpackPrefetch: true */ '~/patterns/organisms/carousel/carousel.vue');

@Component({
    components: { NewsCard, Carousel }
})
export default class LatestNews extends Vue {
    @Prop({ type: Array, default: () => [] }) readonly tags!: TagType[];

    @Prop() readonly municipality!: MunicipalityType;

    @Prop() readonly title!: string;

    carouselSettings: object = {
        slidesPerView: 1.25,
        center: false,
        spaceBetween: 24,
        breakpointsInverse: true,
        breakpoints: {
            // hard-coded breakpoint..
            480: {
                slidesPerView: 2.25
            },
            // hard-coded breakpoint..
            992: {
                slidesPerView: 3
            }
        }
    };

    loadNews() {
        return this.$store.dispatch('news/load', { municipality: this.municipality, tags: this.tags });
    }

    serverPrefetch() {
        return this.loadNews();
    }

    mounted() {
        if (!this.articles) {
            this.loadNews();
        }
    }

    get articles() {
        return this.$store.getters['news/news'](this.municipality, this.tags);
    }

    get items(): number {
        return this.articles ? this.articles.length : 0;
    }
}
