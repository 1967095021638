




import { Vue, Component, Prop } from 'vue-property-decorator';
import { TabsBlockFields } from '~/types/TabsBlock';
import { MunicipalityFields } from '~/types/Municipality';
import Tabs from '~/patterns/organisms/tabs/tabs.vue';
import Tab from '~/patterns/molecules/tab/tab.vue';
import TabLink from '~/patterns/atoms/tab-link/tab-link.vue';
import slugify from '~/helpers/slugify';
import convertToTabIcon from '~/helpers/convertToTabIcon';

@Component({
    components: {
        Tabs,
        Tab,
        TabLink,
        ContentBlocks: () => import('~/patterns/organisms/_collections/content-blocks/content-blocks.vue')
    },
    methods: { slugify, convertToTabIcon }
})
export default class TabsBlock extends Vue {
    @Prop() readonly fields!: TabsBlockFields;
    @Prop() readonly municipality!: MunicipalityFields;

    activeId: number;

    constructor() {
        super();
        this.activeId = 0;
    }
}
