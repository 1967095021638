



















import { Component, Vue } from 'vue-property-decorator';
import GlobalEvents from 'vue-global-events';

export interface PropsData {
    activeId: number;
    slug: string;
}

@Component({
    components: { GlobalEvents }
})
export default class Tabs extends Vue {
    get active(): number {
        if (
            !this.$slots ||
            !this.$slots.navigation ||
            !this.$slots.navigation[0] ||
            !this.$slots.navigation[0].componentOptions ||
            !this.$slots.navigation[0].componentOptions.propsData
        ) {
            return 0;
        }
        const { activeId } = this.$slots.navigation[0].componentOptions.propsData as PropsData;
        return activeId || 0;
    }

    get tabs(): string[] {
        if (!this.$slots || !this.$slots.navigation) {
            return [];
        }

        return (
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            this?.$slots?.navigation.reduce((acc: string[], comp: any) => {
                if (!comp.componentOptions || !comp.componentOptions.propsData) {
                    return acc;
                }
                const { slug } = comp.componentOptions.propsData as PropsData;
                return [...acc, slug];
            }, []) || []
        );
    }

    nextTab() {
        const index = this.active + 1;
        const active = index >= this.tabs.length ? 0 : index;
        this.$emit('update:activeId', active);
    }

    previousTab() {
        const index = this.active - 1;
        const active = index < 0 ? this.tabs.length - 1 : index;
        this.$emit('update:activeId', active);
    }
}

// declare module 'vue-global-events'
