<template>
    <!-- eslint-disable vue-a11y/tabindex-no-positive -->
    <div v-if="!dismissed" class="cookiebar">
        <div class="cookiebar__body">
            <div v-if="$slots.header" class="cookiebar__header">
                <slot name="header"></slot>
            </div>
            <!-- eslint-disable vue-a11y/tabindex-no-positive -->
            <div v-if="$slots.message" class="cookiebar__message">
                <slot name="message"></slot>
            </div>

            <div class="cookiebar__buttons">
                <!-- eslint-disable vue-a11y/tabindex-no-positive -->
                <a href="#" class="cookiebar__button cookiebar__button-accept" tabindex="1" @click.prevent="onAccept">
                    <slot name="acceptText">Accept</slot>
                </a>
                <!-- eslint-disable vue-a11y/tabindex-no-positive -->
                <a href="#" class="cookiebar__button cookiebar__button-dismiss" tabindex="1" @click.prevent="onDeny">
                    <slot name="denyText">I do not accept</slot>
                </a>
            </div>
        </div>
    </div>
</template>

<style lang="less" src="./cookiebar.less"></style>

<script>
'use strict';

import { mapActions, mapGetters } from 'vuex';

export const EVENT_ACCEPT = 'accept';
export const EVENT_DENY = 'deny';

export default {
    computed: {
        ...mapGetters('cookie', ['dismissed', 'denied', 'accepted'])
    },
    methods: {
        onAccept() {
            this.$emit(EVENT_ACCEPT);
            this.accept();
        },

        onDeny() {
            this.$emit(EVENT_DENY);
            this.deny();
        },

        ...mapActions('cookie', ['deny', 'accept'])
    }
};
</script>
