













import { Component } from 'vue-property-decorator';
import FocusLock from 'vue-focus-lock';
import Navigation from '~/patterns/organisms/navigation/navigation.vue';
import Modal from '~/patterns/organisms/modal/modal.vue';
import ModalOpener from '~/patterns/molecules/modal-opener/modal-opener.vue';

@Component({
    components: { Modal, ModalOpener, FocusLock }
})
export default class NavigationModal extends Navigation {
    get showSecondaryLinks() {
        return this.$store.getters['navigation/showSecondaryLinks'];
    }
}
