




import { Vue, Component, Prop } from 'vue-property-decorator';

@Component({})
export default class Tab extends Vue {
    @Prop({ type: Number }) readonly id!: number;

    @Prop({ type: String }) readonly slug!: string;

    @Prop({ type: Number }) readonly activeId!: number;

    get href(): string {
        return '#' + this.slug;
    }

    get isActive(): boolean {
        return this.activeId === this.id;
    }
}
