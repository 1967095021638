




import { mixins } from 'vue-class-component';
import { Component, Prop } from 'vue-property-decorator';
import { LinkEntry } from '~/types/Link';
import LinkCard from '~/patterns/molecules/_cards/link-card/link-card.vue';
import slugify from '~/helpers/slugify';
import { LinkOverviewFields } from '~/types/LinkOverview';
import DisplayLogicMixin from '~/mixins/DisplayLogic.vue';

@Component({
    components: { LinkCard }
})
export default class LinkOverview extends mixins(DisplayLogicMixin) {
    @Prop() readonly fields!: LinkOverviewFields;

    get slug(): string {
        return slugify(this.fields.name) || '';
    }

    get linkItems() {
        const { municipality } = this.$store.state.municipality;
        const links = [] as LinkEntry[];
        if (this.fields.links) {
            this.fields.links.forEach((item: LinkEntry) => {
                if (
                    !item.fields ||
                    (this.fields.hideActive && this.$route.path.includes(item.fields.page.fields.slug))
                ) {
                    return;
                }

                if (!municipality && !item.fields.showOnMunicipalities) {
                    links.push(item);
                } else if (municipality) {
                    const dontHide =
                        item.fields.hideOnMunicipalities &&
                        !item.fields.hideOnMunicipalities.find(elem => elem.sys.id === municipality.sys.id);
                    const show =
                        item.fields.showOnMunicipalities &&
                        item.fields.showOnMunicipalities.find(elem => elem.sys.id === municipality.sys.id);
                    const noConstraints = !item.fields.hideOnMunicipalities && !item.fields.showOnMunicipalities;

                    if (dontHide || show || noConstraints) {
                        links.push(item);
                    }
                }
            });
        }

        if (this.fields.randomize) {
            links.sort(() => Math.random() - 0.5);
        }

        return links.slice(0, this.fields.showMaxAmountOfItems || 18);
    }
}
