




import { Vue, Component, Prop } from 'vue-property-decorator';
import { format } from 'date-fns';
import LinkList from '~/patterns/molecules/link-list/link-list.vue';
import BtnSocial from '~/patterns/atoms/button-social/button-social.vue';
import GhButton from '~/patterns/atoms/button/button.vue';
import GhLink from '~/patterns/atoms/link/link.vue';
import Accordion from '~/patterns/organisms/accordion/accordion.vue';
import AccordionItem from '~/patterns/molecules/accordion-item/accordion-item.vue';
import { FooterFields } from '~/types/Footer';
import { getPathForEntry } from '~/helpers/routes';

@Component({
    components: { LinkList, BtnSocial, GhButton, Accordion, AccordionItem, GhLink },
    methods: { getPathForEntry }
})
export default class Footer extends Vue {
    @Prop() readonly footer!: FooterFields;

    year: string;

    constructor() {
        super();
        this.year = format(new Date(), 'yyyy');
    }
}
