




import { Vue, Component, Prop } from 'vue-property-decorator';
import { format, parseISO } from 'date-fns';
import nlLocale from 'date-fns/locale/nl';
import { ArticleType } from '~/types/Article';
import { HasImage } from '~/interfaces/image';
import { getImage } from '~/helpers/image';
import CloudinaryImage from '~/patterns/atoms/cloudinary-image/cloudinary-image.vue';
import GhLink from '~/patterns/atoms/link/link.vue';
import { getPathForEntry } from '~/helpers/routes';

@Component({
    name: 'news-card',
    components: { GhLink, CloudinaryImage },
    methods: { getPathForEntry }
})
export default class NewsCard extends Vue {
    @Prop() item: ArticleType | undefined;

    @Prop(String) readonly classes!: string;

    get publishDate(): string {
        if (this.item) {
            return format(parseISO(this.item.fields.publishDate), 'D MMMM YYYY', { locale: nlLocale });
        }
        return '';
    }

    get image(): HasImage {
        return getImage((this.item as ArticleType).fields);
    }
}
