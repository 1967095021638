


































































































































































import { Vue, Component, Prop } from 'vue-property-decorator';
import { Entry } from 'contentful';

import { ContentBlockEntry } from '~/types/ContentBlocks';
import { MunicipalityFields, MunicipalityType } from '~/types/Municipality';
import { ProductSolutionOpportunityCollection, ProductSolutionEntry } from '~/types/Product';

import ContactArea from '~/patterns/organisms/_content-blocks/contact-area/contact-area.vue';
import ContentfulButton from '~/patterns/molecules/contentful-button.vue';
import CtaBanner from '~/patterns/organisms/_content-blocks/cta-banner/cta-banner.vue';
import EventCollection from '~/patterns/organisms/_content-blocks/event-collection/event-collection.vue';
import Faq from '~/patterns/organisms/_content-blocks/faq/faq.vue';
import FlexibleGridBlock from '~/patterns/organisms/_content-blocks/flexible-grid-block/flexible-grid-block.vue';
import LinkOverview from '~/patterns/organisms/_content-blocks/link-overview/link-overview.vue';
import MediaOnly from '~/patterns/organisms/_content-blocks/media-only/media-only.vue';
import PopularIn from '~/patterns/organisms/_content-blocks/popular-in/popular-in.vue';
import ProductTree from '~/patterns/organisms/_content-blocks/product-tree/product-tree.vue';
import RelatedExperiences from '~/patterns/organisms/_content-blocks/related-experiences/related-experiences.vue';
import RichText from '~/patterns/molecules/rich-text/rich-text.vue';
import SolutionOpportunities from '~/patterns/organisms/_content-blocks/solution-opportunities/solution-opportunities.vue';
import SolutionOpportunity from '~/patterns/organisms/_content-blocks/solution-opportunity/solution-opportunity.vue';
import TabsBlock from '~/patterns/organisms/_content-blocks/tabs-block/tabs-block.vue';
import TextOnly from '~/patterns/organisms/_content-blocks/text-only/text-only.vue';
import EmbeddedIframe from '~/patterns/atoms/embedded-iframe.vue';
import FinancialBenefitOverview from '~/patterns/organisms/_content-blocks/financial-benefit-overview/financial-benefit-overview.vue';
import LatestNews from '~/patterns/organisms/_content-blocks/latest-news/latest-news.vue';
import Review from '~/patterns/organisms/_content-blocks/review/review.vue';
const StoreLocator = () => import(/* webpackPrefetch: true */ '~/patterns/molecules/store-locator.vue');

@Component({
    name: 'content-blocks',
    components: {
        ContactArea,
        ContentfulButton,
        CtaBanner,
        EventCollection,
        Faq,
        FinancialBenefitOverview,
        FlexibleGridBlock,
        LatestNews,
        LinkOverview,
        MediaOnly,
        PopularIn,
        ProductTree,
        RelatedExperiences,
        Review,
        RichText,
        SolutionOpportunities,
        SolutionOpportunity,
        StoreLocator,
        TabsBlock,
        TextOnly,
        EmbeddedIframe
    }
})
export default class ContentBlocks extends Vue {
    // A back-reference to the entry containing the content blocks
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    @Prop() readonly pageEntry: Entry<any>;

    @Prop({ type: Array, default: () => [] }) readonly blocks!: ContentBlockEntry[];

    // These are only the fields of the Municipality Entry
    @Prop() readonly municipalityFields!: MunicipalityFields;

    // This is the actual Municipality Entry (including .sys)
    @Prop() readonly municipality!: MunicipalityType;

    @Prop() readonly opportunities?: ProductSolutionOpportunityCollection;

    @Prop() readonly solutions?: ProductSolutionEntry[];

    @Prop({ type: Boolean, default: false }) readonly nested;

    get fields() {
        if (this.blocks && this.blocks.length) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            let randomizable: any[] = [];
            const blocks = this.blocks.map(block => {
                const item = { randomizable: false, ...block.fields };
                if (item.randomizable) {
                    randomizable.push(item);
                    return null;
                }
                return item;
            });
            randomizable = randomizable.sort(() => Math.random() - 0.5);
            return blocks.map(block => {
                return block === null ? randomizable.pop() : block;
            });
        }
        return [];
    }

    get contentTypes() {
        if (this.blocks && this.blocks.length) {
            return this.blocks.map(block => (block.sys.contentType ? block.sys.contentType.sys.id : 'NULL'));
        }
        return [];
    }

    get modifierClasses() {
        if (this.nested) {
            return 'cta-banner--nested';
        }
        return '';
    }
}
