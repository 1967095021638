
import { Vue, Component, Prop } from 'vue-property-decorator';
import eventBus from '~/helpers/event-bus';

@Component({})

export default class ModalOpener extends Vue {
    @Prop(String) type!: string;

    @Prop(String) id!: string;

    showModal() {
        eventBus.$emit('openModal', { id: this.id, type: this.type });
    }

    render() {
        if (this.$scopedSlots && this.$scopedSlots.default) {
            return this.$scopedSlots.default({ showModal: this.showModal });
        }
        return null;
    }
}
