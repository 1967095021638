






import { Vue, Component, Prop } from 'vue-property-decorator';
import { Block } from '@contentful/rich-text-types';

import { ContentBlockEntry } from '~/types/ContentBlocks';

@Component({
    components: { ContentBlocks: () => import('~/patterns/organisms/_collections/content-blocks/content-blocks.vue') }
})
export default class EmbeddedEntry extends Vue {
    @Prop() node: Block;

    get targetEntry(): ContentBlockEntry | null {
        return this.node.data.target;
    }
}
