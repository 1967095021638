












































import { Vue, Component, Prop } from 'vue-property-decorator';
import { ContentBlockFields } from '~/types/ContentBlocks';
import { ExperienceType, ExperienceEntry } from '~/types/Experience';
import Card from '~/patterns/molecules/_cards/card/card.vue';
import { getImageWithSolutionFallback } from '~/helpers/image';
import { ProductSolutionCollection, ProductSolutionEntry } from '~/types/Product';
import CloudinaryImage from '~/patterns/atoms/cloudinary-image/cloudinary-image.vue';

const Carousel = () => import(/* webpackPrefetch: true */ '~/patterns/organisms/carousel/carousel.vue');

@Component({
    components: { Card, CloudinaryImage, Carousel },
    methods: {
        getImage(experience: ExperienceEntry) {
            const experienceFields = experience.fields ? experience.fields : undefined;
            const solutions = experience.fields.appliedSolutions ? experience.fields.appliedSolutions : undefined;
            return getImageWithSolutionFallback(experienceFields, solutions as ProductSolutionCollection | undefined);
        }
    }
})
export default class RelatedExperiences extends Vue {
    @Prop() readonly fields!: ContentBlockFields;
    @Prop() readonly solutions?: ProductSolutionEntry[];
    @Prop() readonly pageId!: string;

    // Can't reference enum directly in template
    experienceType = ExperienceType;

    get carouselSettings(): object | null {
        if (process.server) {
            return null;
        }
        return {
            slidesPerView: 1.25,
            center: false,
            // spaceBetween: 12, // the grid utility classes will add some internal spacing
            breakpointsInverse: true,
            breakpoints: {
                // hard-coded breakpoint..
                480: {
                    slidesPerView: 2
                },
                // hard-coded breakpoint..
                992: {
                    slidesPerView: 3 // this.items > 2 ? 3 : this.items
                }
            }
        };
    }

    loadExperiences() {
        return this.$store.dispatch('experiences/getExperiencesBySolutions', {
            solutions: this.solutions,
            pageId: this.pageId
        });
    }

    serverPrefetch() {
        return this.loadExperiences();
    }

    mounted() {
        if (!this.experiences) {
            this.loadExperiences();
        }
    }

    get experiences() {
        return this.$store.getters['experiences/relatedExperiences'](this.solutions, this.pageId);
    }

    get items(): number {
        return this.experiences && this.experiences.items ? this.experiences.items.length : 0;
    }
}
